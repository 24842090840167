import React from "react";
import MyAppBar from "../Components/Appbar";

const ProjectPage = () =>{
// need to create a backend to host the projects
// create dummy json data for now
    return(
        <div>
            <MyAppBar></MyAppBar>
            <br style={{height:'50px'}}></br>

        </div>
    )
}

export default ProjectPage;